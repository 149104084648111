import React from 'react'
import Layout from "../components/organisms/layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentServices from "../components/organisms/ContentServices/ContentServices";
const Servicios = () => {
  return (
    <Layout>
      <SEO
        title='Servicios'
        description='Cold Chain Logistics'
      />
      <ContentServices />
    </Layout>
  )
}

export default Servicios
