import React from 'react'
import Banner from "../Banner/Banner";
import ServiceContent from "../../molecules/ServiceContent/ServiceContent";
import Contact from "../Contact/Contact";
import {graphql, useStaticQuery} from "gatsby";
import Clients from "../../molecules/Clients/Clients";

const ContentServices = () => {


  const { allContentfulSeccionServicio } = useStaticQuery(
    graphql`
        query Services_Query {
            allContentfulSeccionServicio {
                nodes {
                    banner {
                        file {
                            url
                        }
                        gatsbyImageData
                        description
                    }
                    descripcionPrincipal {
                        raw
                    }
                    servicios {
                        imagen {
                            file {
                                url
                            }
                            gatsbyImageData
                            description
                        }
                        descripcion {
                            raw
                        }
                    }
                }
            }
        }

    `
  )

  const contentInfo = allContentfulSeccionServicio.nodes[0]


  return (
    <>
      <Banner
        description={contentInfo.descripcionPrincipal}
        image={contentInfo.banner}
      />
      {contentInfo.servicios.map((service, i) => {
        let direction = i % 2
        direction = direction === 0  ? 'left' : 'right'
        return(
          <ServiceContent
            key={`service-${i}`}
            direction={direction}
            description={service.descripcion}
            image={service.imagen}/>

        )
      })}
      <Clients />
      <Contact />
    </>
  )
}

export default ContentServices
